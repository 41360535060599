import Vue from "vue";
import App from "./layouts/Layout.vue";
import router from "./router";
import "./assets/css/main.scss";
import iconByExtension from "@/utils/IconByExtension";
import colorByExtension from "@/utils/ColorByExtension";
import legendColorsBySector from "@/utils/LegendColorsBySector";
import openFile from "@/utils/OpenFile";
import search from "@/utils/Search";
import axios from "axios";
import VueAxios from "vue-axios";
import { actions, state } from "@/store";
import VueHighcharts from "vue-highcharts";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import sankey from "highcharts/modules/sankey";
import dependencyWeel from "highcharts/modules/dependency-wheel";
import Popper from "vue-popperjs";
import VueMultiselect from "vue-multiselect";
import infiniteScroll from "vue-infinite-scroll";
import tailwindConfig from "../tailwindcss/index.js";
import VueInitialsImg from "vue-initials-img";
import ChartGauge from "@/charts/ChartGauge.vue";
import ChartArea from "@/charts/ChartArea.vue";
import ChartAreaMobile from "@/charts/ChartAreaMobile.vue";
import ChartBubble from "@/charts/ChartBubble.vue";
import ChartColCompare from "@/charts/ChartColCompare.vue";
import ChartColCompareSmall from "@/charts/ChartColCompareSmall.vue";
import ChartColCompareSmallMobile from "@/charts/ChartColCompareSmallMobile.vue";
import ChartLineCompare from "@/charts/ChartLineCompare.vue";
import ChartDependencyWheel from "@/charts/ChartDependencyWheel.vue";
import MarginAverage from "@/charts/MarginAverage.vue";
import { typeDocs } from "@/utils/TypeDocs";
import setColorsBySector from "@/utils/SetColorsBySector";
import { optionsFilterInterIntra } from "@/utils/OptionsFilterInterIntra";
import { optionsFilterPipeline } from "@/utils/OptionsFilterPipeline";
import colorsByOptionCommsAndDash from "@/utils/ColorsByOptionCommsAndDash";
import { fileRoute, imageRoute } from "./utils/Routes";
import mediaType from "@/utils/MediaType";
import typeModel from "@/utils/TypeModel";
import goLink from "@/utils/GoLink";
import is_url from "@/utils/ValidateURL";
import alertsCount from "@/utils/AlertsCount";
import colorSocialNetwork from "@/utils/ColorSocialNetwork";
import platform from "@/utils/Platform";
import typeNotification from "@/utils/TypeNotification";
import goToSection from "@/utils/GoToSection";
import iconByNotification from "@/utils/IconByNotificationType";
import colorByNotificationType from "@/utils/colorByNotificationType";
import meLike from "@/utils/MeLike";
import { optionFilterProjects } from "@/utils/OptionsProjects";
import colorByExtensionWithoutBackground from "@/utils/ColorByExtensionWithoutBackground";
import { check } from "vue-ctk-date-time-picker";
import("@mdi/font/css/materialdesignicons.min.css");
import("vue2-animate/dist/vue2-animate.min.css");

import Echo from "laravel-echo";
import Pusher from "pusher-js";
//window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
  encrypted: false,
  namespace: false,
  wsHost: process.env.VUE_APP_API_URL_SOCKETS,
  wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
  disableStats: true,
  enabledTransports: ["ws"],
  forceTLS: false,
  transports: ["websocket", "polling"],
  authEndpoint: process.env.VUE_APP_API_URL + "broadcasting/auth",
  auth: {
    // headers:{
    //     Authorization: 'Bearer ' + state.token
    // }
  },
});

const http = axios.create();

Vue.prototype.$iconByExtension = iconByExtension;
Vue.prototype.$colorByExtension = colorByExtension;
Vue.prototype.$colorByExtensionWithoutBackground =
  colorByExtensionWithoutBackground;
Vue.prototype.$search = search;
Vue.prototype.$setColorsBySector = setColorsBySector;
Vue.prototype.$legendColorsBySector = legendColorsBySector;
Vue.prototype.$optionsFilterInterIntra = optionsFilterInterIntra;
Vue.prototype.$openFile = openFile;
Vue.prototype.$typeModel = typeModel;
Vue.prototype.$optionsFilterProjects = optionFilterProjects;

http.interceptors.request.use(
  (config) => {
    let token = state.token;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    if (status === 401) {
      actions.resetAuth();
      router.push({ name: "login" });
    }
  }
);

Vue.use(VueAxios, http);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper /* { default global options } */);

Highcharts.setOptions({
  lang: {
    //        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    //        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    //        weekdays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    thousandsSep: ".",
  },
});
highchartsMore(Highcharts);
sankey(Highcharts);
dependencyWeel(Highcharts);
solidGauge(Highcharts);

Vue.use(VueHighcharts);

import("vue-popperjs/dist/vue-popper.css");
Vue.component("popper", Popper);

Vue.component("multiselect", {
  mixins: [VueMultiselect],
  props: {
    selectLabel: { default: "↵" },
    deselectLabel: { default: "x" },
    selectedLabel: { default: "✓" },
  },
});

Vue.use(infiniteScroll);

const VueCtkDateTimePicker = () => import("vue-ctk-date-time-picker");

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

// name is optional
var VueTouch = require("vue-touch");
Vue.use(VueTouch);

// VUE MOMENT
const moment = require("moment");
moment.locale("en");
//require('moment/locale/es')
Vue.use(require("vue-moment"), {
  moment,
});

Vue.prototype.timeFormat = "YYYY-MM-DD";

Vue.prototype.tailwindColors = tailwindConfig.theme.colors;
Vue.prototype.catColors = [
  "#AC6FC7",
  "#BFAF97",
  "#FE861E",
  "#F790D3",
  "#92E38A",
  "#EA4A1A",
  "#6FC7C4",
  "#C7B96F",
  "#6F8DC7",
  "#EA9A1A",
  "#D7B96F",
  "#C1F96F",
  "#FC6FC7",
  "#EFAF97",
  "#D790D3",
  "#52E38A",
  "#DA4A1A",
];

Vue.prototype.$typeDocs = typeDocs;
Vue.prototype.$fileRoute = fileRoute;
Vue.prototype.$imageRoute = imageRoute;
Vue.prototype.$mediaType = mediaType;
Vue.prototype.$colorSocialNetwork = colorSocialNetwork;
// CALENDAR
//import 'vue-event-calendar/dist/style.css' //^1.1.10, CSS has been extracted as one file, so you can easily update it.
//import vueEventCalendar from 'vue-event-calendar'
//Vue.use(vueEventCalendar, {locale: 'en'})
const fullCalendar = () => import("vue-fullcalendar");

Vue.component("full-calendar", fullCalendar);

Vue.use(VueInitialsImg);

import VTooltip from "v-tooltip";

Vue.use(VTooltip);

// GLOBAL COMPONENTS

Vue.component(
  "Separator",
  require("@/components/Globals/Separator.vue").default
);

Vue.component("Loader", require("@/components/Globals/Loader.vue").default);

//Projects Management mobile components

const Tnr = import("@/components/Mobile/ProjectsManagement/Tnr.vue");
Vue.component("Tnr", Tnr);

// GLOBAL CHARTS
Vue.component("ChartGauge", ChartGauge);

Vue.component("ChartArea", ChartArea);

Vue.component("ChartAreaMobile", ChartAreaMobile);

Vue.component("ChartBubble", ChartBubble);

Vue.component("ChartColCompare", ChartColCompare);

Vue.component("ChartColCompareSmall", ChartColCompareSmall);

Vue.component("ChartColCompareSmallMobile", ChartColCompareSmallMobile);

Vue.component("ChartLineCompare", ChartLineCompare);

Vue.component("ChartDependencyWheel", ChartDependencyWheel);

Vue.component("MarginAverage", MarginAverage);

Vue.prototype.$typeNotification = typeNotification;
Vue.prototype.$goLink = goLink;
Vue.prototype.$alertsCount = alertsCount;
Vue.prototype.$optionsFilterPipeline = optionsFilterPipeline;
Vue.prototype.$colorsByOptionCommsAndDash = colorsByOptionCommsAndDash;
Vue.prototype.$is_url = is_url;
Vue.prototype.$platform = platform;
Vue.prototype.$goToSection = goToSection;
Vue.config.productionTip = false;
Vue.prototype.$iconByNotification = iconByNotification;
Vue.prototype.$meLike = meLike;
Vue.prototype.$colorByNotificationType = colorByNotificationType;

const Avatar = import("vue-avatar");
Vue.component("avatar", Avatar);

//Custom functions and filters
Vue.filter("reduceBigNumbers", function (number, decimals) {
  if (number === "N/A" || number === "TBD") return number;
  return reduceBigNumbers(number, decimals);
});
Vue.filter("numberFormat", function (number, decimals) {
  if (number === "N/A" || number === "TBD") return number;
  return numberFormat(number, decimals);
});

Vue.filter("removeCharacters", function (str) {
  return str.replace(/[^A-Z0-9]+/gi, "_");
});

Vue.filter("randomNumber", function (min, max) {
  return randomNumber(min, max);
});

export function reduceBigNumbers(number, decimals = 2) {
  if (number > 1000000 || number < -1000000) {
    return numberFormat(number / 1000000, decimals) + "M";
  }
  // if (number > 1000 || number < -1000) {
  //     return numberFormat(number / 1000, decimals) + 'K';
  // }
  return numberFormat(number, 0);
}

export function numberFormat(number, decimals = 0) {
  if (number == null) {
    return "N/A";
  }
  number = +number;

  // if (typeof number == 'number') {
  //     decimals = (decimals != undefined) ?  decimals : 2
  //     return parseFloat(number).toLocaleString('en-EN', {
  //         maximumFractionDigits: decimals,
  //         minimumFractionDigits: decimals
  //     })
  //     .replace(',', '')
  //     // .replace(/,/g, '@')
  //     //     .replace('.', ',')
  //     //         .replace(/@/g, '.');
  //     // return parseFloat(number.toFixed(parseFloat(decimals))).toLocaleString('en-EN');
  // }
  // return number

  if (typeof number == "number") {
    decimals = decimals != undefined ? decimals : 2;
    return parseFloat(number)
      .toLocaleString("en", {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      })
      .replace(/,/g, "@")
      .replace(",", ".")
      .replace(/@/g, ",");
    // return parseFloat(number.toFixed(parseFloat(decimals))).toLocaleString('es-ES');
  }

  return number;
}

export function randomNumber(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

var mixin = {
  methods: {
    removeCharacters(str) {
      return str.replace(/[^A-Z0-9]+/gi, "_");
    },
  },
};

Vue.mixin({
  methods: {
    successOrDangerClass(val, comparation = 0) {
      return {
        "text-success": val >= comparation && this.isNotNaAndTbd(val),
        "text-danger": val < comparation && this.isNotNaAndTbd(val),
      };
    },
    isNotNaAndTbd(val) {
      return this.isNotTbd(val) && this.isNotNa(val);
    },
    isNotTbd(val) {
      return val !== "TBD";
    },
    isNotNa(val) {
      return val !== "N/A";
    },
  },
});

new Vue({
  mixins: [mixin],
  router,
  render: (h) => h(App),
}).$mount("#app");
