module.exports = {
  theme: {
    colors: {
      font: {
        default: "#ffffff",
        aux: "#13F2E3",
        light: "#CECECE",
        gray: "#95989A",
        "gray-15": "rgba(149, 152, 154, 0.15)",
        dark: "#6E6F6F",
        darken: "#495A59",
      },
      aux: {
        default: "#13F2E3",
        40: "rgba(19, 242, 227, 0.4)",
        15: "rgba(19, 242, 227, 0.15)",
      },
      body: "#1D1D21",
      module: {
        top: "#2B3236",
        top_70: "rgba(43, 50, 54, 0.7)",
        bottom: "#21282E",
        bottom_70: "rgba(33, 40, 46, 0.7)",
        bottom_90: "rgba(33, 40, 46, 0.9)",
        bottom_50: "rgba(33, 40, 46, 0.5)",
        bottom_10: "rgba(33, 40, 46, 0.10)",
      },
      box: {
        //#1D1D21
        default: "rgba(29, 29, 33, 1)",
        15: "rgba(29, 29, 33, 0.15)",
        40: "rgba(29, 29, 33, 0.4)",
        80: "rgba(29, 29, 33, 0.8)",
      },
      beige: {
        default: "#BFAF97",
      },
      filters: {
        default: "#323C43",
        80: "rgba(50, 60, 67, 0.8)",
      },
      good: {
        default: "#11B0EB",
        30: "rgba(17, 176, 235, 0.3)",
        50: "rgba(17, 176, 235, 0.5)",
      },
      success: {
        default: "#59BEB6",
        30: "rgba(89, 190, 182, 0.3)",
      },
      warning: {
        default: "#FFD351",
        30: "rgba(255, 211, 81, 0.3)",
      },
      danger: {
        default: "#FD7B7B",
        15: "rgba(253, 123, 123, 0.15)",
        30: "rgba(253, 123, 123, 0.3)",
      },
      purple: {
        default: "#A279E2",
        40: "rgb(162, 121, 226, .4)",
        30: "rgb(162, 121, 226, .3)",
        15: "rgb(162, 121, 226, .15)",
      },
      violet: "#AC6FC7",
      violet_30: "rgb(172, 111, 199, .3)",
      lilac: {
        default: "#814CD6",
        dark: "#6511EB",
      },
      blue: {
        default: "#2363C9",
      },
      pink: {
        default: "#D38DDE",
      },
      orange: {
        default: "#FE694E",
        15: "rgb(254, 105, 78, 0.15)",
        30: "rgb(254, 105, 78, 0.3)",
      },
      yellow: {
        default: "#FFD351",
      },
      gray: {
        600: "#718096",
      },
      black: "#000000",
      white: "#ffffff",
      green: {
        default: "#92E38A",
        40: "rgb(146, 227, 138, 0.4)",
        30: "rgb(146, 227, 138, 0.3)",
        15: "rgb(146, 227, 138, 0.15)",
        dark: "#30AE60",
      },
      plan: {
        default: "#6F8DC7",
      },
      progress: {
        default: "#2D363C",
        bar_light: "#8CEABB",
        bar_dark: "#378F7B",
        bar_gray: "#475462",
      },
      gold: {
        default: "#FFB014",
        30: "rgba(255, 176, 20, 0.30)",
      },
      LastUpdates: "#13F2E3",
      TheEssential: "#FFD351",
      Alerts: "#FD7B7B",
      TalkingPoints: "#11B0EB",
      Media: "#FE694E",
      EYEvents: "#AC6FC7",
      Wins: "#92E38A",
      CommAndPodcast: "#C96FAD",
      percentage: {
        25: "#11B0EB",
        50: "#59BEB6",
        75: "#FFD351",
        100: "#FD7B7B",
      },
      silver: "#C0C0C0",
      bronze: "#CD7F32",
      tnrbody: "#24272D",
    },
    extend: {
      gradients: (theme) => ({
        //bg-gradient-{name}
        module: [
          "bottom",
          theme("colors.module.top"),
          theme("colors.module.bottom"),
        ],
        module_70: [
          "bottom",
          theme("colors.module.top_70"),
          theme("colors.module.bottom_70"),
        ],
        progress: [
          "bottom",
          theme("colors.progress.bar_light"),
          theme("colors.progress.bar_dark"),
        ],
        module_scroll_fade: [
          "top",
          theme("colors.module.bottom"),
          "rgba(0,0,0,0)",
        ],
        //'box_scroll_fade': ['top','rgb(32, 36, 40)', 'rgba(36, 37, 41, 0)'],
        box_scroll_fade: [
          "top",
          theme("colors.box.default"),
          "rgba(36, 37, 41, 0)",
        ],
        progress_lilac: [
          "right",
          theme("colors.lilac.default"),
          theme("colors.pink.default"),
        ],
        progress_orange: [
          "right",
          theme("colors.yellow.default"),
          theme("colors.orange.default"),
        ],
        purcashed: [
          "right",
          theme("colors.orange.default"),
          theme("colors.yellow.default"),
        ],
        sold: [
          "right",
          theme("colors.lilac.dark"),
          theme("colors.blue.default"),
        ],
      }),
      buttons: (theme) => ({
        //btn-{name}
        aux: [theme("colors.aux.default")],
        gray: [theme("colors.font.gray")],
        danger: [theme("colors.danger.default")],
      }),
      pills: (theme) => ({
        //pill-{name}
        ass: ["#4F65E0"],
        adv: ["#0ACFFE"],
        pas: ["#CE7CC5"],
        tas: ["#569A38"],
        gray: [theme("colors.font.gray")],
        aux: [theme("colors.aux.default")],
        LastUpdates: [theme("colors.aux.default")],
        TheEssential: [theme("colors.warning.default")],
        Alerts: [theme("colors.danger.default")],
        TalkingPoints: [theme("colors.good.default")],
        Media: [theme("colors.orange.default")],
        EYEvents: [theme("colors.violet")],
        Wins: [theme("colors.green.default")],
        CommAndPodcast: ["#C96FAD"],
      }),
      spacing: {
        "1px": "1px",
        "2px": "2px",
        7: "1.75rem",
        14: "3.5rem",
        18: "4.5rem",
        22: "5.5rem",
        28: "7rem",
        30: "7.5rem",
        34: "8.5rem",
        36: "9rem",
        44: "10.5rem",
        62: "15.5rem",
        72: "18rem",
        84: "21rem",
        96: "24rem",
        // '100': '25rem',
        104: "26rem",
        110: "27.5rem",
        122: "32rem",
        160: "40rem",
        192: "48rem",
        "3/10": "30%",
        "2/9": "22.2222222%",
        "4/9": "44.4444444%",
        "5/9": "55.5555555%",
      },
      height: (theme) => ({
        ...theme("width"),
      }),
      fontSize: {
        "3xs": ".5rem",
        xxs: ".625rem",
        xs: ".75rem",
        sm: ".875rem",
        tiny: ".875rem",
        base: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem",
        "7xl": "5rem",
        "8xl": "8rem",
      },
      boxShadow: {
        md: "0px 3px 6px #000000D5",
      },
      transitionProperty: {
        size: "width, height",
      },
      minHeight: {
        8: "2rem",
        10: "2.5rem",
        20: "5rem",
        32: "8rem",
        48: "12rem",
      },
      borderRadisu: {
        xl: "1rem",
      },
      maxHeight: {
        16: "4rem",
        28: "7rem",
      },
    },
    screens: {
      motoG4: "360px",
      pixel2: {
        raw: "(min-width: 411px) and (max-height: 731px)",
      },
      pixel2xl: {
        raw: "(min-width: 411px) and (min-height: 823px)",
      },
      iphoneX: {
        raw: "(min-width: 375px) and (max-height: 812px)",
      },
      iphone678: {
        raw: "(min-width: 375px) and (max-height: 667px)",
      },
      iphone678Plus: {
        raw: "(min-width: 414px) and (min-height: 736px)",
      },
    },
  },
  // variants: {
  // 	pills:['hover']
  // },
  variants: [
    //'responsive',
    //'group-hover',
    //'focus-within',
    "hover",
    //'focus',
    //'active',
    //'first',
    //'last',
    //'odd',
    //'even'
  ],
  plugins: [
    require("@tailwindcss/custom-forms"),
    require("./plugins/gradients"),
    require("./plugins/buttons"),
    require("./plugins/pills"),
  ],
};
